@import "variables";

// print view (default)
body.print {
  height: 180vh;
  padding: $padding;
  overflow: hidden;

  #calendar {
    padding: 125px 75px 75px 75px;
    transform: scale(0.315);
    transform-origin: top;
    outline: 4px solid $primary;
    margin-bottom: 0;
    &::before {
      top: 60px;
    }
    $columns: 18;
    & > div {
      width: calc(100% / #{$columns});
    }
  }
}
