@import "variables";

body {
  background: $background;
  color: $foreground;
  width: 100%;
  padding: $padding $padding calc(#{padding} * 2) $padding;
  box-sizing: border-box;
  text-align: center;
  font-family: "Fira Code", monospace;
}

// calendar
section#calendar {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  justify-content: space-around;
  padding: 100px $padding $padding $padding;
  margin-bottom: calc($padding * 2);

  div {
    width: calc(100% / #{$columns});
    text-align: center;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: none;
    flex-direction: column;
    gap: $gap;
    margin-bottom: $gap;

    .circle {
      display: block;
      width: 100%;
      padding-bottom: 100%;
      border-radius: 50%;
      background-color: none;
      outline: $border solid $foreground;
      outline-color: $primary;
      transform: scale($scale);
      position: relative;
    }

    small {
      width: 100%;
      padding: 0 5px;
      display: inline-block;
      box-sizing: border-box;

      p {
        display: inline-block;
        width: 40%;
      }

      p:first-of-type {
        text-align: left;
        font-weight: 300;
      }

      p:last-of-type {
        text-align: right;
        font-weight: 500;
      }
    }
    &.past {
      .circle {
        &::after {
          content: "";
          width: calc(100% + $border);
          height: $border;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
    &.today {
      .circle {
        transform: scale(1);
        animation: pulse 1s ease-in-out infinite alternate;
      }
      @keyframes pulse {
        from {
          transform: scale($scale);
        }
        to {
          transform: scale(0.85);
        }
      }
    }
    &.weekend {
      .circle {
        background: rgba($primary, 1);
      }
    }
  }

  // month styling
  @import "months";
}
