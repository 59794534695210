// bw

$primary: $black;
$secondary: $white;

body.theme-bw {
  background: $primary;
  color: $secondary;

  section#calendar {
    div {
      .circle {
        outline-color: $secondary;
      }
      &.weekend {
        .circle {
          background: $secondary;
        }
      }
      &.past {
        .circle::after {
          background: $secondary;
        }
      }
    }
  }
  #toggle,
  #progress,
  #date,
  #time,
  #theme {
    color: $secondary;
    background: $primary;
    outline-color: $secondary;
  }
  #toggle {
    &::after {
      background: $secondary !important;
    }
  }
}

body.bw.print {
  section#calendar {
    outline-color: $secondary;
  }

  // hide outlines in print mode for this theme only
  #progress,
  #date,
  #time,
  #theme {
    outline: none;
  }
}
