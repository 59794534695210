// cc-29

$dark-blue: #3a3858;
$dark-navy: #212123;
$dark-purple: #352b42;
$dark-teal: #567b79;
$dark-gray: #45444f;
$dark-red: #b45252;
$dark-magenta: #6a536e;
$dark-brown: #4b4158;
$dark-green: #7b7243;

$medium-blue: #43436a;
$medium-purple: #4e584a;
$medium-teal: #8ab060;
$medium-gray: #646365;
$medium-red: #80493a;
$medium-magenta: #cf8acb;
$medium-brown: #b8b5b9;
$medium-green: #b2b47e;

$light-blue: #4b80ca;
$light-purple: #5f556a;
$light-teal: #a2dcc7;
$light-gray: #868188;
$light-red: #a77b5b;
$light-magenta: #ede19e;
$light-brown: #d3a068;
$light-green: #edc8c4;

$off-white: #f2f0e5;
$light-blue-green: #68c2d3;
$light-pink: #e5ceb4;

$hover-color: $light-magenta;

// months

$january: $dark-red;
$february: $light-brown;
$march: $light-blue;
$april: $dark-teal;
$may: $dark-magenta;
$june: $light-red;
$july: $medium-green;
$august: $medium-gray;
$september: $medium-blue;
$october: $light-purple;
$november: $light-blue-green;
$december: $light-pink;

// defaults
$foreground: $dark-navy;
$background: $off-white;

$primary: $dark-navy;
$secondary: $dark-red;


body.theme-cc-29 {
    background: $primary;
    color: $secondary;
  
    section#calendar {
        outline-color: $secondary;
      div {
        .circle {
          //outline-color: $secondary;
        }
        &.weekend {
          .circle {
            //background: $secondary;
          }
        }
        &.past {
          .circle::after {
            //background: $secondary;
          }
        }
      }
    }
  
    #toggle,
    #progress,
    #date,
    #time,
    #theme {
      color: $hover-color;
      background: $primary;
      outline-color: $secondary;
    }
  
    #toggle {
      &::after {
        background: $hover-color !important;
      }
    }
  
    #theme {
      .option {
        outline: $hover-color solid 2px;
        &.active {
          background: $secondary;
          &::after {
            content: "";
            background: $hover-color;
          }
          &:hover {
            &::after {
                background: $hover-color;
            }
          }
        }
      }
    }
  }
  
  body.cc-29.print {
    section#calendar {
      outline-color: $secondary;
    }
  
    // hide outlines in print mode for this theme only
    #progress,
    #date,
    #time,
    #theme {
      outline: none;
    }
  
    #theme {
      .option {
        outline: $secondary 2px solid;
        &::after, &:hover {
          background: $secondary;
        }
      }
    }
  }
  