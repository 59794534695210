@import "variables";

// extra info
#toggle,
#progress,
#date,
#time,
#theme {
  position: fixed;
  border-radius: 20px;
  height: 40px;
  outline: 2px solid $primary;
  background: $background;
  z-index: 9999;
  font-weight: 500;
}

#date,
#time,
#progress {
  padding: 9px 15px;
}

#toggle {
  right: $padding;
  bottom: $padding;
  width: 75px;
  cursor: pointer;

  &::after {
    content: "";
    background: $secondary;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    position: absolute;
    top: 2px;
    right: 37px;
    transition: ease-in-out 0.2s;
  }

  &.on {
    &::after {
      right: 2px;
      background: $primary;
    }
  }
}

#progress {
  left: $padding;
  bottom: $padding;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    border-radius: $padding;
    z-index: 9999;
    background: black;
  }
}
#date {
  top: $padding;
  left: $padding;
}
#time {
  right: $padding;
  top: $padding;
}

#theme {
  right: $padding;
  top: 50%;
  transform: translateY(-50%) translateX(50%) scale(.75);
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 50px;

  .option {
    height: 32px;
    width: 32px;
    outline: $primary solid 2px;
    border-radius: 16px;
    margin-bottom: 12px;
    cursor: pointer;
    position: relative;


    &#bw {
      &:hover {
        outline: $secondary;
        background: $primary;
      }
    }
    &#cc-29 {
      &:hover {
        background: $dark-red;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scale(0.85);

        border-radius: 18px;
        background: $primary;
      }
    }

    &:hover {
      background: $primary;
    }
  }
}

body.print {
  #theme {
    transform: translateY(-50%) translateX(0) scale(1);
  }
}
