@import "variables";

@mixin month-color($color) {
  .circle {
    outline-color: $color;
    background: rgba($color, 0);
    &::after {
      background: $color;
    }
  }
  &.weekend {
    .circle {
      background: $color;
    }
  }
}

.january {
  $color: $january;
  @include month-color($color);
}

.february {
  $color: $february;
  @include month-color($color);
}

.march {
  $color: $march;
  @include month-color($color);
}

.april {
  $color: $april;
  @include month-color($color);
}

.may {
  $color: $may;
  @include month-color($color);
}

.june {
  $color: $june;
  @include month-color($color);
}

.july {
  $color: $july;
  @include month-color($color);
}

.august {
  $color: $august;
  @include month-color($color);
}

.september {
  $color: $september;
  @include month-color($color);
}

.october {
  $color: $october;
  @include month-color($color);
}

.november {
  $color: $november;
  @include month-color($color);
}

.december {
  $color: $december;
  @include month-color($color);
}
