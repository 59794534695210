@import "https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;500;700&display=swap";
html {
  -webkit-text-size-adjust: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-size-adjust: 100%;
  width: 100%;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizespeed;
  position: relative;
}

*, :after, :before {
  box-sizing: border-box;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

input:required, input {
  box-shadow: none;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #fff;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

input:focus {
  outline: none;
}

audio, canvas, video {
  max-width: 100%;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
  display: inline-block;
}

picture {
  display: inline-block;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  background: none;
  border: 0;
}

button[disabled], html input[disabled] {
  cursor: default;
}

[disabled] {
  pointer-events: none;
}

input[type="checkbox"], input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  background: none;
  border: 0;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

hr {
  box-sizing: content-box;
  height: 1px;
  page-break-after: always;
  width: 100%;
  background: #000;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 100%;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: monospace;
}

small {
  font-size: 75%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -5px;
}

sup {
  top: -5px;
}

button, input, optgroup, select, textarea {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  outline: 0;
  padding: 0;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  width: 100%;
  border: 0;
  display: block;
}

fieldset {
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

template {
  display: none;
}

body.theme-cc-29 {
  color: #b45252;
  background: #212123;
}

body.theme-cc-29 section#calendar {
  outline-color: #b45252;
}

body.theme-cc-29 #toggle, body.theme-cc-29 #progress, body.theme-cc-29 #date, body.theme-cc-29 #time, body.theme-cc-29 #theme {
  color: #ede19e;
  background: #212123;
  outline-color: #b45252;
}

body.theme-cc-29 #toggle:after {
  background: #ede19e !important;
}

body.theme-cc-29 #theme .option {
  outline: 2px solid #ede19e;
}

body.theme-cc-29 #theme .option.active {
  background: #b45252;
}

body.theme-cc-29 #theme .option.active:after {
  content: "";
  background: #ede19e;
}

body.theme-cc-29 #theme .option.active:hover:after {
  background: #ede19e;
}

body.cc-29.print section#calendar {
  outline-color: #b45252;
}

body.cc-29.print #progress, body.cc-29.print #date, body.cc-29.print #time, body.cc-29.print #theme {
  outline: none;
}

body.cc-29.print #theme .option {
  outline: 2px solid #b45252;
}

body.cc-29.print #theme .option:after, body.cc-29.print #theme .option:hover {
  background: #b45252;
}

body.theme-bw {
  color: #fff;
  background: #000;
}

body.theme-bw section#calendar div .circle {
  outline-color: #fff;
}

body.theme-bw section#calendar div.weekend .circle, body.theme-bw section#calendar div.past .circle:after {
  background: #fff;
}

body.theme-bw #toggle, body.theme-bw #progress, body.theme-bw #date, body.theme-bw #time, body.theme-bw #theme {
  color: #fff;
  background: #000;
  outline-color: #fff;
}

body.theme-bw #toggle:after {
  background: #fff !important;
}

body.bw.print section#calendar {
  outline-color: #fff;
}

body.bw.print #progress, body.bw.print #date, body.bw.print #time, body.bw.print #theme {
  outline: none;
}

body.theme-wb {
  color: #000;
  background: #fff;
}

body.theme-wb section#calendar, body.theme-wb section#calendar div .circle {
  outline-color: #000;
}

body.theme-wb section#calendar div.weekend .circle, body.theme-wb section#calendar div.past .circle:after {
  background: #000;
}

body.theme-wb #toggle, body.theme-wb #progress, body.theme-wb #date, body.theme-wb #time, body.theme-wb #theme {
  color: #000;
  background: #fff;
  outline-color: #000;
}

body.theme-wb #toggle:after {
  background: #000 !important;
}

body.theme-wb #theme .option {
  outline: 2px solid #000;
}

body.theme-wb #theme .option.active {
  background: #000;
}

body.theme-wb #theme .option.active:after {
  content: "";
  background: #000;
}

body.wb.print section#calendar {
  outline-color: #000;
}

body.wb.print #progress, body.wb.print #date, body.wb.print #time, body.wb.print #theme {
  outline: none;
}

body.wb.print #theme .option {
  outline: 2px solid #000;
}

body.wb.print #theme .option:after, body.wb.print #theme .option:hover {
  background: #000;
}

body.theme-cc-29 {
  color: #b45252;
  background: #212123;
}

body.theme-cc-29 section#calendar {
  outline-color: #b45252;
}

body.theme-cc-29 #toggle, body.theme-cc-29 #progress, body.theme-cc-29 #date, body.theme-cc-29 #time, body.theme-cc-29 #theme {
  color: #ede19e;
  background: #212123;
  outline-color: #b45252;
}

body.theme-cc-29 #toggle:after {
  background: #ede19e !important;
}

body.theme-cc-29 #theme .option {
  outline: 2px solid #ede19e;
}

body.theme-cc-29 #theme .option.active {
  background: #b45252;
}

body.theme-cc-29 #theme .option.active:after {
  content: "";
  background: #ede19e;
}

body.theme-cc-29 #theme .option.active:hover:after {
  background: #ede19e;
}

body.cc-29.print section#calendar {
  outline-color: #b45252;
}

body.cc-29.print #progress, body.cc-29.print #date, body.cc-29.print #time, body.cc-29.print #theme {
  outline: none;
}

body.cc-29.print #theme .option {
  outline: 2px solid #b45252;
}

body.cc-29.print #theme .option:after, body.cc-29.print #theme .option:hover {
  background: #b45252;
}

body.theme-bw {
  color: #fff;
  background: #000;
}

body.theme-bw section#calendar div .circle {
  outline-color: #fff;
}

body.theme-bw section#calendar div.weekend .circle, body.theme-bw section#calendar div.past .circle:after {
  background: #fff;
}

body.theme-bw #toggle, body.theme-bw #progress, body.theme-bw #date, body.theme-bw #time, body.theme-bw #theme {
  color: #fff;
  background: #000;
  outline-color: #fff;
}

body.theme-bw #toggle:after {
  background: #fff !important;
}

body.bw.print section#calendar {
  outline-color: #fff;
}

body.bw.print #progress, body.bw.print #date, body.bw.print #time, body.bw.print #theme {
  outline: none;
}

body.theme-wb {
  color: #000;
  background: #fff;
}

body.theme-wb section#calendar, body.theme-wb section#calendar div .circle {
  outline-color: #000;
}

body.theme-wb section#calendar div.weekend .circle, body.theme-wb section#calendar div.past .circle:after {
  background: #000;
}

body.theme-wb #toggle, body.theme-wb #progress, body.theme-wb #date, body.theme-wb #time, body.theme-wb #theme {
  color: #000;
  background: #fff;
  outline-color: #000;
}

body.theme-wb #toggle:after {
  background: #000 !important;
}

body.theme-wb #theme .option {
  outline: 2px solid #000;
}

body.theme-wb #theme .option.active {
  background: #000;
}

body.theme-wb #theme .option.active:after {
  content: "";
  background: #000;
}

body.wb.print section#calendar {
  outline-color: #000;
}

body.wb.print #progress, body.wb.print #date, body.wb.print #time, body.wb.print #theme {
  outline: none;
}

body.wb.print #theme .option {
  outline: 2px solid #000;
}

body.wb.print #theme .option:after, body.wb.print #theme .option:hover {
  background: #000;
}

body {
  color: #212123;
  width: 100%;
  padding: 30px 30px calc(padding * 2) 30px;
  box-sizing: border-box;
  text-align: center;
  background: #f2f0e5;
  font-family: Fira Code, monospace;
}

section#calendar {
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5px;
  margin-bottom: 60px;
  padding: 100px 30px 30px;
  display: flex;
}

section#calendar div {
  width: 5.55556%;
  text-align: center;
  flex-wrap: none;
  border: 1px solid #0000;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
}

section#calendar div .circle {
  width: 100%;
  background-color: none;
  border-radius: 50%;
  outline: 3px solid #fff;
  padding-bottom: 100%;
  display: block;
  position: relative;
  transform: scale(.75);
}

section#calendar div small {
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  display: inline-block;
}

section#calendar div small p {
  width: 40%;
  display: inline-block;
}

section#calendar div small p:first-of-type {
  text-align: left;
  font-weight: 300;
}

section#calendar div small p:last-of-type {
  text-align: right;
  font-weight: 500;
}

section#calendar div.past .circle:after {
  content: "";
  width: calc(100% + 3px);
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

section#calendar div.today .circle {
  animation: 1s ease-in-out infinite alternate pulse;
  transform: scale(1);
}

@keyframes pulse {
  from {
    transform: scale(.75);
  }

  to {
    transform: scale(.85);
  }
}

section#calendar div.weekend .circle {
  background: #fff;
}

section#calendar body.theme-cc-29 {
  color: #b45252;
  background: #212123;
}

section#calendar body.theme-cc-29 section#calendar {
  outline-color: #b45252;
}

section#calendar body.theme-cc-29 #toggle, section#calendar body.theme-cc-29 #progress, section#calendar body.theme-cc-29 #date, section#calendar body.theme-cc-29 #time, section#calendar body.theme-cc-29 #theme {
  color: #ede19e;
  background: #212123;
  outline-color: #b45252;
}

section#calendar body.theme-cc-29 #toggle:after {
  background: #ede19e !important;
}

section#calendar body.theme-cc-29 #theme .option {
  outline: 2px solid #ede19e;
}

section#calendar body.theme-cc-29 #theme .option.active {
  background: #b45252;
}

section#calendar body.theme-cc-29 #theme .option.active:after {
  content: "";
  background: #ede19e;
}

section#calendar body.theme-cc-29 #theme .option.active:hover:after {
  background: #ede19e;
}

section#calendar body.cc-29.print section#calendar {
  outline-color: #b45252;
}

section#calendar body.cc-29.print #progress, section#calendar body.cc-29.print #date, section#calendar body.cc-29.print #time, section#calendar body.cc-29.print #theme {
  outline: none;
}

section#calendar body.cc-29.print #theme .option {
  outline: 2px solid #b45252;
}

section#calendar body.cc-29.print #theme .option:after, section#calendar body.cc-29.print #theme .option:hover {
  background: #b45252;
}

section#calendar body.theme-bw {
  color: #fff;
  background: #000;
}

section#calendar body.theme-bw section#calendar div .circle {
  outline-color: #fff;
}

section#calendar body.theme-bw section#calendar div.weekend .circle, section#calendar body.theme-bw section#calendar div.past .circle:after {
  background: #fff;
}

section#calendar body.theme-bw #toggle, section#calendar body.theme-bw #progress, section#calendar body.theme-bw #date, section#calendar body.theme-bw #time, section#calendar body.theme-bw #theme {
  color: #fff;
  background: #000;
  outline-color: #fff;
}

section#calendar body.theme-bw #toggle:after {
  background: #fff !important;
}

section#calendar body.bw.print section#calendar {
  outline-color: #fff;
}

section#calendar body.bw.print #progress, section#calendar body.bw.print #date, section#calendar body.bw.print #time, section#calendar body.bw.print #theme {
  outline: none;
}

section#calendar body.theme-wb {
  color: #000;
  background: #fff;
}

section#calendar body.theme-wb section#calendar, section#calendar body.theme-wb section#calendar div .circle {
  outline-color: #000;
}

section#calendar body.theme-wb section#calendar div.weekend .circle, section#calendar body.theme-wb section#calendar div.past .circle:after {
  background: #000;
}

section#calendar body.theme-wb #toggle, section#calendar body.theme-wb #progress, section#calendar body.theme-wb #date, section#calendar body.theme-wb #time, section#calendar body.theme-wb #theme {
  color: #000;
  background: #fff;
  outline-color: #000;
}

section#calendar body.theme-wb #toggle:after {
  background: #000 !important;
}

section#calendar body.theme-wb #theme .option {
  outline: 2px solid #000;
}

section#calendar body.theme-wb #theme .option.active {
  background: #000;
}

section#calendar body.theme-wb #theme .option.active:after {
  content: "";
  background: #000;
}

section#calendar body.wb.print section#calendar {
  outline-color: #000;
}

section#calendar body.wb.print #progress, section#calendar body.wb.print #date, section#calendar body.wb.print #time, section#calendar body.wb.print #theme {
  outline: none;
}

section#calendar body.wb.print #theme .option {
  outline: 2px solid #000;
}

section#calendar body.wb.print #theme .option:after, section#calendar body.wb.print #theme .option:hover {
  background: #000;
}

section#calendar .january .circle {
  background: #b4525200;
  outline-color: #b45252;
}

section#calendar .january .circle:after, section#calendar .january.weekend .circle {
  background: #b45252;
}

section#calendar .february .circle {
  background: #d3a06800;
  outline-color: #d3a068;
}

section#calendar .february .circle:after, section#calendar .february.weekend .circle {
  background: #d3a068;
}

section#calendar .march .circle {
  background: #4b80ca00;
  outline-color: #4b80ca;
}

section#calendar .march .circle:after, section#calendar .march.weekend .circle {
  background: #4b80ca;
}

section#calendar .april .circle {
  background: #567b7900;
  outline-color: #567b79;
}

section#calendar .april .circle:after, section#calendar .april.weekend .circle {
  background: #567b79;
}

section#calendar .may .circle {
  background: #6a536e00;
  outline-color: #6a536e;
}

section#calendar .may .circle:after, section#calendar .may.weekend .circle {
  background: #6a536e;
}

section#calendar .june .circle {
  background: #a77b5b00;
  outline-color: #a77b5b;
}

section#calendar .june .circle:after, section#calendar .june.weekend .circle {
  background: #a77b5b;
}

section#calendar .july .circle {
  background: #b2b47e00;
  outline-color: #b2b47e;
}

section#calendar .july .circle:after, section#calendar .july.weekend .circle {
  background: #b2b47e;
}

section#calendar .august .circle {
  background: #64636500;
  outline-color: #646365;
}

section#calendar .august .circle:after, section#calendar .august.weekend .circle {
  background: #646365;
}

section#calendar .september .circle {
  background: #43436a00;
  outline-color: #43436a;
}

section#calendar .september .circle:after, section#calendar .september.weekend .circle {
  background: #43436a;
}

section#calendar .october .circle {
  background: #5f556a00;
  outline-color: #5f556a;
}

section#calendar .october .circle:after, section#calendar .october.weekend .circle {
  background: #5f556a;
}

section#calendar .november .circle {
  background: #68c2d300;
  outline-color: #68c2d3;
}

section#calendar .november .circle:after, section#calendar .november.weekend .circle {
  background: #68c2d3;
}

section#calendar .december .circle {
  background: #e5ceb400;
  outline-color: #e5ceb4;
}

section#calendar .december .circle:after, section#calendar .december.weekend .circle {
  background: #e5ceb4;
}

body.theme-cc-29 {
  color: #b45252;
  background: #212123;
}

body.theme-cc-29 section#calendar {
  outline-color: #b45252;
}

body.theme-cc-29 #toggle, body.theme-cc-29 #progress, body.theme-cc-29 #date, body.theme-cc-29 #time, body.theme-cc-29 #theme {
  color: #ede19e;
  background: #212123;
  outline-color: #b45252;
}

body.theme-cc-29 #toggle:after {
  background: #ede19e !important;
}

body.theme-cc-29 #theme .option {
  outline: 2px solid #ede19e;
}

body.theme-cc-29 #theme .option.active {
  background: #b45252;
}

body.theme-cc-29 #theme .option.active:after {
  content: "";
  background: #ede19e;
}

body.theme-cc-29 #theme .option.active:hover:after {
  background: #ede19e;
}

body.cc-29.print section#calendar {
  outline-color: #b45252;
}

body.cc-29.print #progress, body.cc-29.print #date, body.cc-29.print #time, body.cc-29.print #theme {
  outline: none;
}

body.cc-29.print #theme .option {
  outline: 2px solid #b45252;
}

body.cc-29.print #theme .option:after, body.cc-29.print #theme .option:hover {
  background: #b45252;
}

body.theme-bw {
  color: #fff;
  background: #000;
}

body.theme-bw section#calendar div .circle {
  outline-color: #fff;
}

body.theme-bw section#calendar div.weekend .circle, body.theme-bw section#calendar div.past .circle:after {
  background: #fff;
}

body.theme-bw #toggle, body.theme-bw #progress, body.theme-bw #date, body.theme-bw #time, body.theme-bw #theme {
  color: #fff;
  background: #000;
  outline-color: #fff;
}

body.theme-bw #toggle:after {
  background: #fff !important;
}

body.bw.print section#calendar {
  outline-color: #fff;
}

body.bw.print #progress, body.bw.print #date, body.bw.print #time, body.bw.print #theme {
  outline: none;
}

body.theme-wb {
  color: #000;
  background: #fff;
}

body.theme-wb section#calendar, body.theme-wb section#calendar div .circle {
  outline-color: #000;
}

body.theme-wb section#calendar div.weekend .circle, body.theme-wb section#calendar div.past .circle:after {
  background: #000;
}

body.theme-wb #toggle, body.theme-wb #progress, body.theme-wb #date, body.theme-wb #time, body.theme-wb #theme {
  color: #000;
  background: #fff;
  outline-color: #000;
}

body.theme-wb #toggle:after {
  background: #000 !important;
}

body.theme-wb #theme .option {
  outline: 2px solid #000;
}

body.theme-wb #theme .option.active {
  background: #000;
}

body.theme-wb #theme .option.active:after {
  content: "";
  background: #000;
}

body.wb.print section#calendar {
  outline-color: #000;
}

body.wb.print #progress, body.wb.print #date, body.wb.print #time, body.wb.print #theme {
  outline: none;
}

body.wb.print #theme .option {
  outline: 2px solid #000;
}

body.wb.print #theme .option:after, body.wb.print #theme .option:hover {
  background: #000;
}

body.print {
  height: 180vh;
  padding: 30px;
  overflow: hidden;
}

body.print #calendar {
  transform-origin: top;
  outline: 4px solid #fff;
  margin-bottom: 0;
  padding: 125px 75px 75px;
  transform: scale(.315);
}

body.print #calendar:before {
  top: 60px;
}

body.print #calendar > div {
  width: 5.55556%;
}

body.theme-cc-29 {
  color: #b45252;
  background: #212123;
}

body.theme-cc-29 section#calendar {
  outline-color: #b45252;
}

body.theme-cc-29 #toggle, body.theme-cc-29 #progress, body.theme-cc-29 #date, body.theme-cc-29 #time, body.theme-cc-29 #theme {
  color: #ede19e;
  background: #212123;
  outline-color: #b45252;
}

body.theme-cc-29 #toggle:after {
  background: #ede19e !important;
}

body.theme-cc-29 #theme .option {
  outline: 2px solid #ede19e;
}

body.theme-cc-29 #theme .option.active {
  background: #b45252;
}

body.theme-cc-29 #theme .option.active:after {
  content: "";
  background: #ede19e;
}

body.theme-cc-29 #theme .option.active:hover:after {
  background: #ede19e;
}

body.cc-29.print section#calendar {
  outline-color: #b45252;
}

body.cc-29.print #progress, body.cc-29.print #date, body.cc-29.print #time, body.cc-29.print #theme {
  outline: none;
}

body.cc-29.print #theme .option {
  outline: 2px solid #b45252;
}

body.cc-29.print #theme .option:after, body.cc-29.print #theme .option:hover {
  background: #b45252;
}

body.theme-bw {
  color: #fff;
  background: #000;
}

body.theme-bw section#calendar div .circle {
  outline-color: #fff;
}

body.theme-bw section#calendar div.weekend .circle, body.theme-bw section#calendar div.past .circle:after {
  background: #fff;
}

body.theme-bw #toggle, body.theme-bw #progress, body.theme-bw #date, body.theme-bw #time, body.theme-bw #theme {
  color: #fff;
  background: #000;
  outline-color: #fff;
}

body.theme-bw #toggle:after {
  background: #fff !important;
}

body.bw.print section#calendar {
  outline-color: #fff;
}

body.bw.print #progress, body.bw.print #date, body.bw.print #time, body.bw.print #theme {
  outline: none;
}

body.theme-wb {
  color: #000;
  background: #fff;
}

body.theme-wb section#calendar, body.theme-wb section#calendar div .circle {
  outline-color: #000;
}

body.theme-wb section#calendar div.weekend .circle, body.theme-wb section#calendar div.past .circle:after {
  background: #000;
}

body.theme-wb #toggle, body.theme-wb #progress, body.theme-wb #date, body.theme-wb #time, body.theme-wb #theme {
  color: #000;
  background: #fff;
  outline-color: #000;
}

body.theme-wb #toggle:after {
  background: #000 !important;
}

body.theme-wb #theme .option {
  outline: 2px solid #000;
}

body.theme-wb #theme .option.active {
  background: #000;
}

body.theme-wb #theme .option.active:after {
  content: "";
  background: #000;
}

body.wb.print section#calendar {
  outline-color: #000;
}

body.wb.print #progress, body.wb.print #date, body.wb.print #time, body.wb.print #theme {
  outline: none;
}

body.wb.print #theme .option {
  outline: 2px solid #000;
}

body.wb.print #theme .option:after, body.wb.print #theme .option:hover {
  background: #000;
}

#toggle, #progress, #date, #time, #theme {
  height: 40px;
  z-index: 9999;
  background: #f2f0e5;
  border-radius: 20px;
  outline: 2px solid #fff;
  font-weight: 500;
  position: fixed;
}

#date, #time, #progress {
  padding: 9px 15px;
}

#toggle {
  width: 75px;
  cursor: pointer;
  bottom: 30px;
  right: 30px;
}

#toggle:after {
  content: "";
  width: 36px;
  height: 36px;
  background: #000;
  border-radius: 18px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 2px;
  right: 37px;
}

#toggle.on:after {
  background: #fff;
  right: 2px;
}

#progress {
  bottom: 30px;
  left: 30px;
}

#progress:after {
  content: "";
  height: 100%;
  width: 0%;
  z-index: 9999;
  background: #000;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

#date {
  top: 30px;
  left: 30px;
}

#time {
  top: 30px;
  right: 30px;
}

#theme {
  height: auto;
  width: auto;
  border-radius: 50px;
  flex-direction: column;
  padding: 10px;
  display: flex;
  top: 50%;
  right: 30px;
  transform: translateY(-50%)translateX(50%)scale(.75);
}

#theme .option {
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 16px;
  outline: 2px solid #fff;
  margin-bottom: 12px;
  position: relative;
}

#theme .option#bw:hover {
  background: #fff;
  outline: #000;
}

#theme .option#cc-29:hover {
  background: #b45252;
}

#theme .option:last-child {
  margin-bottom: 0;
}

#theme .option.active:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 18px;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.85);
}

#theme .option:hover {
  background: #fff;
}

body.print #theme {
  transform: translateY(-50%)translateX(0)scale(1);
}

body.theme-cc-29 {
  color: #b45252;
  background: #212123;
}

body.theme-cc-29 section#calendar {
  outline-color: #b45252;
}

body.theme-cc-29 #toggle, body.theme-cc-29 #progress, body.theme-cc-29 #date, body.theme-cc-29 #time, body.theme-cc-29 #theme {
  color: #ede19e;
  background: #212123;
  outline-color: #b45252;
}

body.theme-cc-29 #toggle:after {
  background: #ede19e !important;
}

body.theme-cc-29 #theme .option {
  outline: 2px solid #ede19e;
}

body.theme-cc-29 #theme .option.active {
  background: #b45252;
}

body.theme-cc-29 #theme .option.active:after {
  content: "";
  background: #ede19e;
}

body.theme-cc-29 #theme .option.active:hover:after {
  background: #ede19e;
}

body.cc-29.print section#calendar {
  outline-color: #b45252;
}

body.cc-29.print #progress, body.cc-29.print #date, body.cc-29.print #time, body.cc-29.print #theme {
  outline: none;
}

body.cc-29.print #theme .option {
  outline: 2px solid #b45252;
}

body.cc-29.print #theme .option:after, body.cc-29.print #theme .option:hover {
  background: #b45252;
}

body.theme-bw {
  color: #fff;
  background: #000;
}

body.theme-bw section#calendar div .circle {
  outline-color: #fff;
}

body.theme-bw section#calendar div.weekend .circle, body.theme-bw section#calendar div.past .circle:after {
  background: #fff;
}

body.theme-bw #toggle, body.theme-bw #progress, body.theme-bw #date, body.theme-bw #time, body.theme-bw #theme {
  color: #fff;
  background: #000;
  outline-color: #fff;
}

body.theme-bw #toggle:after {
  background: #fff !important;
}

body.bw.print section#calendar {
  outline-color: #fff;
}

body.bw.print #progress, body.bw.print #date, body.bw.print #time, body.bw.print #theme {
  outline: none;
}

/*# sourceMappingURL=index.408b43a1.css.map */
