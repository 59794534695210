$white: #fff;
$black: #000;

$scale: 0.75;
$gap: 5px;
$columns: 18;
$border: 3px;

$padding: 30px;

$primary: $black;
$secondary: $white;

@import "themes/cc-29";
@import "themes/bw";
@import "themes/wb";
