// wb

$primary: $white;
$secondary: $black;

body.theme-wb {
  background: $primary;
  color: $secondary;

  section#calendar {
    outline-color: $secondary;
    div {
      .circle {
        outline-color: $secondary;
      }
      &.weekend {
        .circle {
          background: $secondary;
        }
      }
      &.past {
        .circle::after {
          background: $secondary;
        }
      }
    }
  }

  #toggle,
  #progress,
  #date,
  #time,
  #theme {
    color: $secondary;
    background: $primary;
    outline-color: $secondary;
  }

  #toggle {
    &::after {
      background: $secondary !important;
    }
  }

  #theme {
    .option {
      outline: $secondary solid 2px;
      &.active {
        background: $secondary;
        &::after {
          content: "";
          background: $secondary;
        }
      }
    }
  }
}

body.wb.print {
  section#calendar {
    outline-color: $secondary;
  }

  // hide outlines in print mode for this theme only
  #progress,
  #date,
  #time,
  #theme {
    outline: none;
  }

  #theme {
    .option {
      outline: $secondary 2px solid;
      &::after, &:hover {
        background: $secondary;
      }
    }
  }
}
